import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  CircularProgress,
} from "@mui/material";
import "../../styles/MembershipTypes.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const cardStyles = {
  cursor: "pointer",
  transition: "0.3s",
  margin: "1.5%",
  padding: "1.5%",
  borderRadius: "10px",
  textAlign: "center",
  backgroundColor: "linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    transform: "scale(1.05)",
    backgroundColor: "gold",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
  },
};

const selectedCardStyles = {
  backgroundColor: "gold",
  border: "2px solid #007bff",
};

const MembershipTypeStep = ({ handleMembershipTypeSelect }) => {
  const [selectedMembership, setSelectedMembership] = useState("");
  const [loading, setLoading] = useState(false);

  const membershipOptions = [
    {
      value: "Junior",
      label: "Junior ",
      desc: "Looking to build character? Want to be a leader? Want to make a difference?",
      price: "FREE",
      image: "https://picsum.photos/400/200",
      Age: "Under 14",
      benefits: [
        "Participate in exclusive events",
        "Learn new skills",
        "Be a part of the community",
        "Build character of a leader",
      ],
    },
    {
      value: "Executive",
      label: "Executive",
      desc: (
        <p>
          Looking to make a difference? Want to build character? Want to be a
          leader? Looking for hands on experince? Want to make a difference?{" "}
          <br />
          Join the executive team!
        </p>
      ),
      price: "$10 yearly",
      image: "https://picsum.photos/400/200",
      Age: "14 to 25",
      benefits: [
        "Participate in exclusive events",
        "Take part in clubs of your interest",
        "Get trained to be the leader of tomorrow",
        "Play an active role in the community",
        "Hands on experience in Leadership",
        "Be part of the executive team",
      ],
    },
    {
      value: "Supporter & Alumni",
      label: "Supporter & Alumni",
      desc: "Looking to help build the next generation of leaders? Want to advise and mentor the leaders of tommorow?  ",
      price: "$30 yearly",
      image: "https://picsum.photos/400/200",
      Age: "25+",
      benefits: [
        "Support the cause",
        "Stay connected with the community",
        "Advise the executive team",
        "Participate in exclusive events",
        "Take part of building the next generation",
      ],
    },
  ];

  const handleMembershipChange = (membership) => {
    setSelectedMembership(membership);
  };

  const handleNext = () => {
    if (selectedMembership) {
      setLoading(true);
      handleMembershipTypeSelect(selectedMembership);
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "95vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "lightblue",
      }}
      // className="membership-registeration-container"
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          padding: "16px",
          borderRadius: "8px",
          height: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // backgroundColor: "lightb lue",
          // "&::after": {
          //   content: '""',
          //   position: "absolute",
          //   top: "50%",
          //   right: "-20px",
          //   borderTop: "25px solid transparent",
          //   borderBottom: "25px solid transparent",
          //   borderLeft: "20px solid lightblue",
          // },
        }}
      >
        <div sx={{ display: "flex", flexWrap: "wrap" }}>
          {membershipOptions.map((option) => (
            <Card
              key={option.value}
              onClick={() => handleMembershipChange(option.value)}
              sx={{
                ...cardStyles,
                ...(selectedMembership === option.value
                  ? selectedCardStyles
                  : {}),
              }}
            >
              <CardContent>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {option.label}
                </Typography>
                <Typography variant="body">({option.Age})</Typography>
              </CardContent>
            </Card>
          ))}
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            flexDirection: "column",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!selectedMembership || loading}
          >
            {loading ? <CircularProgress size={24} /> : "Next"}
          </Button>
          {/* <div>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Already have an account?{" "}
              <Button variant="text" color="primary">
                Login
              </Button>
            </Typography>
          </div> */}
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          padding: "10px",
          textAlign: "center",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        className="right-column-membership"
      >
        <Card
          elevation={3}
          style={{
            padding: "20px",
            minHeight: "300px",
            width: "90%",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "3%",
          }}
        >
          {selectedMembership ? (
            membershipOptions.map((option) => (
              <div
                key={option.value}
                style={{
                  display:
                    selectedMembership === option.value ? "block" : "none",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  {option.label} Membership
                </Typography>
                {/* <img
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    marginBottom: "16px",
                  }}
                  src={option.image}
                  alt={option.label}
                /> */}

                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  {option.desc}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 2,
                  }}
                >
                  Membership Fee: {option.price}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Age: {option.Age}
                </Typography>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {option.benefits.map((feature, index) => (
                    <Grid item xs={6} key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
                        <Typography
                          variant="body1"
                          sx={{ textAlign: "center" }}
                        >
                          {feature}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))
          ) : (
            <div>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                Welcome to the Membership Registration Portal
              </Typography>
              <Typography variant="h5" sx={{ mt: "10%" }}>
                Please select a membership type to see its details
              </Typography>
            </div>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default MembershipTypeStep;
