import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Backdrop,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { db, auth } from "../../../firebase";
import { doc, setDoc } from "firebase/firestore";
import "../../../styles/Signup.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import PinIcon from "@mui/icons-material/Pin";

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const SignupCol = ({ selectedMembership, handleNext }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [doB, setdoB] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [showBackdrop, setShowBackdrop] = useState(false);
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [snackbarAge, setSnackbarAge] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handlePostalCodeChange = (e) => {
    const input = e.target.value;
    // Remove any spaces from the input
    const sanitizedInput = input.replace(/\s/g, "");

    // Add a space after the third character if it's not already there
    const formattedPostalCode = sanitizedInput
      .slice(0, 3)
      .concat(sanitizedInput.length > 3 ? " " + sanitizedInput.slice(3) : "");

    setPostalCode(formattedPostalCode);
  };

  const validateForm = () => {
    let formErrors = {};

    if (!firstName) {
      formErrors.firstName = "First name is required";
    }

    if (!lastName) {
      formErrors.lastName = "Last name is required";
    }

    if (!doB) {
      formErrors.doB = "Date of birth is required";
    }

    // if (typeof doB === "object") {
    //   setdoB(new Date(doB));
    // }

    const postalCodeRegex =
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
    if (!postalCodeRegex.test(postalCode)) {
      formErrors.postalCode = "Invalid postal code";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      formErrors.email = "Invalid email address";
    }

    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?).{8,}$/;
    if (!passwordRegex.test(password)) {
      formErrors.password =
        "Password must contain at least 8 characters, one uppercase, one lowercase, and one number";
    }

    if (password !== confirmPassword) {
      formErrors.password = "Passwords do not match";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      if (selectedMembership === "Executive" && getAge(doB) < 14) {
        setSnackbarMessage(
          "You must be at least 14 years old to sign up for an Executive membership."
        );
        setSnackbarSeverity("error");
        setOpen(true);
        return;
      } else if (selectedMembership === "Junior" && getAge(doB) > 14) {
        setSnackbarMessage(
          "You must be at most 14 years old to sign up for a Junior membership."
        );
        setSnackbarSeverity("error");
        setOpen(true);
        return;
      } else if (selectedMembership === "Executive" && getAge(doB) > 25) {
        setSnackbarMessage(
          "You must be at most 25 years old to sign up for an Executive membership."
        );
        setSnackbarSeverity("error");
        setOpen(true);
        return;
      } else {
        setLoading(true);
        setShowBackdrop(true);
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        user = userCredential.user;
        await setDoc(doc(db, "Users", user.uid), {
          Name: `${firstName} ${lastName}`,
          Email: email,
          DateOfBirth: new Date(doB).toISOString(),
          PostalCode: postalCode,
          EventsAttended: 0,
          MemberType: selectedMembership,
        });
        setSuccess(true);
        setSnackbarMessage("Signup successful!");
        setSnackbarSeverity("success");
        setOpen(true);
        handleNext();
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      alert(errorMessage);
      setErrors({ ...errors, firebase: errorMessage });
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setOpen(true);
    } finally {
      setLoading(false);
      setShowBackdrop(false);
    }
  };

  return (
    <div>
      <Card>
        <CardContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AccountCircleIcon />
                <TextField
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "2%",
                }}
              >
                <TextField
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EventNoteIcon />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    error={!!errors.doB}
                    helperText={errors.doB}
                    label="Date of Birth"
                    onChange={(newValue) => setdoB(newValue)}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PinIcon />
                <TextField
                  error={!!errors.postalCode}
                  helperText={errors.postalCode}
                  inputProps={{ maxLength: 7 }}
                  fullWidth
                  label="Postal Code"
                  variant="outlined"
                  value={postalCode}
                  onChange={handlePostalCodeChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EmailIcon />
                <TextField
                  error={!!errors.email}
                  helperText={errors.email}
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LockIcon />
                <TextField
                  error={!!errors.password}
                  helperText={errors.password}
                  fullWidth
                  label="Password"
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LockIcon />
                <TextField
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  fullWidth
                  label="Confirm Password"
                  variant="outlined"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSignup}>
            {loading ? <CircularProgress size={24} /> : "Sign Up"}
          </Button>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center", // Align content vertically in the center
              justifyContent: "space-between", // Add space between text and the button
              marginBottom: 2, // Add some spacing at the bottom
            }}
          >
            <Typography variant="body1">Change membership type? </Typography>
            <Button color="primary" onClick={() => navigate("/")}>
              Go back
            </Button>
          </Box> */}
        </CardActions>
      </Card>

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        <Typography variant="body1">Agree to our</Typography>
        <Button
          color="primary"
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            marginLeft: 1,
          }}
          onClick={() => {
            openTermsModal();
          }}
        >
          Terms and Conditions
        </Button>
      </Box> */}
    </div>
  );
};

export let user;
export default SignupCol;
