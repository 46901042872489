import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import RegistrationStepper from "../components/Registeration/RegisterationStepper";
import MembershipTypeStep from "../components/Registeration/MembershipType";
import SignupStep from "../components/Registeration/Signup/Signup";
import TestStep from "../components/Registeration/TestLevel";
import SummaryStep from "../components/Registeration/Summary";
import "../styles/Registration.css";

const RegistrationPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedMembershipType, setSelectedMembershipType] = useState("");
  const [stepperClass, setStepperClass] = useState("stepper-container");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Function to handle membership type selection
  const handleMembershipTypeSelect = (membershipType) => {
    setSelectedMembershipType(membershipType);
    handleNext(); // Move to the next step
  };

  useEffect(() => {
    // Place your conditional logic here and update activeStep accordingly
    if (selectedMembershipType !== "Executive" && activeStep === 2) {
      // Update activeStep when conditions are met
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 2 || activeStep === 3) {
      setStepperClass("stepper-container-test");
    }
  }, [activeStep, selectedMembershipType]);

  const stepClass = (step) => {
    if (step === 0) {
      return "membership-stepper";
    } else if (step === 1) {
      return "signup-step";
    } else {
      return "default-step";
    }
  };

  return (
    <div className="stepper-container-wrapper">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
        className={stepClass(activeStep)}
      >
        <div className={stepperClass}>
          <RegistrationStepper
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            className="stepper"
          />
        </div>

        {activeStep === 0 && (
          <MembershipTypeStep
            handleMembershipTypeSelect={handleMembershipTypeSelect}
          />
        )}
        {activeStep === 1 && (
          <SignupStep
            handleNext={handleNext}
            membershipType={selectedMembershipType}
          />
        )}
        {activeStep === 2 && <TestStep handleNext={handleNext} />}

        {activeStep === 3 && <SummaryStep />}
      </Box>
    </div>
  );
};

export default RegistrationPage;
