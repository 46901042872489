import React, { useEffect } from "react";
import { Typography, Button, Container, Paper, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import emailjs from "@emailjs/browser";
import { user } from "../Registeration/Signup/SignUpCol";

const Summary = () => {
  let counter = 0;
  useEffect(() => {
    if (counter === 0) {
      emailjs.init(process.env.REACT_APP_EMAILJS_PUBLICKEY);
      emailjs.init("mailtrap_test_service");
      getDoc(doc(db, "Users", user.uid)).then((docSnap) => {
        if (docSnap.exists()) {
          if (docSnap.data().MemberType === "Executive") {
            emailjs
              .send(
                process.env.REACT_APP_EMAILJS_SERVICEID,
                "member_signup_template",
                {
                  message:
                    docSnap.data().Name +
                    " have signed up and their current email is " +
                    docSnap.data().Email +
                    "." +
                    "\n Thier current membership type is " +
                    docSnap.data().MemberType +
                    "." +
                    "\n Thier current date of birth is " +
                    docSnap.data().DateOfBirth +
                    "." +
                    "\n Thier current Level is " +
                    docSnap.data().level +
                    "." +
                    "\n Thier current postal code is " +
                    docSnap.data().PostalCode,
                  from_name: "Mylo Web",
                  to_name: "Membership Director",
                  member_name: docSnap.data().Name,
                },
                process.env.REACT_APP_EMAILJS_PUBLICKEY
              )
              .then(
                (result) => {},
                (error) => {
                  alert(
                    "Error in retrieving data, refresh the page and try again."
                  );
                }
              );
          } else {
            emailjs
              .send(
                process.env.REACT_APP_EMAILJS_SERVICEID,
                "member_signup_template",
                {
                  message:
                    docSnap.data().Name +
                    " have signed up and their current email is " +
                    docSnap.data().Email +
                    "." +
                    "\n Thier current membership type is " +
                    docSnap.data().MemberType +
                    "." +
                    "\n Thier current date of birth is " +
                    docSnap.data().DateOfBirth +
                    "." +
                    "\n Thier current postal code is " +
                    docSnap.data().PostalCode,

                  from_name: "Mylo Web",
                  to_name: "Membership Director",
                  member_name: docSnap.data().Name,
                },
                process.env.REACT_APP_EMAILJS_PUBLICKEY
              )
              .then(
                (result) => {},
                (error) => {
                  console.error(error.text);
                  alert(
                    "Error in retrieving data, refresh the page and try again."
                  );
                }
              );
          }
        } else {
          console.error("error retrieving data, No such document!");
          alert("Error in retrieving data, refresh the page and try again.");
        }
      });
      counter++;
    } else {
      console.log("email already sent");
    }
  });

  return (
    <Container
      maxWidth="md"
      sx={{
        minHeight: "100vh", // Ensure the Container covers the entire viewport height
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          marginTop: "10vh",
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Typography variant="h3" gutterBottom>
          Summary
        </Typography>

        <CheckCircleIcon sx={{ fontSize: 60, color: "green" }} />

        <Typography variant="h6" gutterBottom>
          Thank you for your application!
        </Typography>

        <Typography variant="body1" gutterBottom>
          Your application has been submitted and is now under review. We will
          send you an email with confirmation and payment details shortly.
        </Typography>

        {/* You can add more summary information here based on the data you have.
          Example:
          <Typography variant="body2" gutterBottom>
            Name: {applicationData.name}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Email: {applicationData.email}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Membership Type: {applicationData.membershipType}
          </Typography>
        */}

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            href="https://youthlo.ca"
          >
            Return to Homepage
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Summary;
