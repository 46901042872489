// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVcxJbfK9Jrx5kabQ3wS28FAuM4KdM5Vw",
  authDomain: "mylo-website.firebaseapp.com",
  projectId: "mylo-website",
  storageBucket: "mylo-website.appspot.com",
  messagingSenderId: "483881912791",
  appId: "1:483881912791:web:4b29cc02b6b1624d47a392",
  measurementId: "G-V2XF40F8EG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
