import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import "../../../styles/Signup.css";
import InfoCol from "./InfoCol";
import SignupCol from "./SignUpCol";

const Signup = ({ membershipType, handleNext }) => {
  const [isAnimating, setAnimating] = useState(false);

  return (
    <div>
      <Grid container className="signup-container">
        <Grid item xs={12} md={6} className="left-column">
          <Box className="left-content">
            <InfoCol />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={`right-column ${isAnimating ? "animate-slide-in" : ""}`}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            className="right-content"
            sx={{
              maxWidth: "90%",
            }}
          >
            <SignupCol
              selectedMembership={membershipType}
              handleNext={handleNext}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Signup;
