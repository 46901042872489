import React from "react";
import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import "../../styles/Stepper.css";

const RegistrationStepper = ({ activeStep }) => {
  const steps = [
    "Membership Type",
    "Create Account",
    "Assesment (Executive)",
    "Summary",
  ];

  return (
    <div>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          borderRadius: "25px",
          marginTop: "5%",
          // top: "-100px",
          marginBottom: "1%",
          width: "110%",
          paddingTop: "2%",
          paddingBottom: "1%",
          // marginLeft: "-5%",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          background: "#FFFFFF",
        }}
        className="stepper-container"
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography>All steps completed!</Typography>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationStepper;
