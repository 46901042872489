import { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Container,
  Grid,
  LinearProgress,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import "../../styles/TestLevel.css";
import { user } from "../Registeration/Signup/SignUpCol";
import { db } from "../../firebase";

const LevelTest = ({ handleNext }) => {
  const [level, setLevel] = useState(1);
  const [testCompleted, setTestCompleted] = useState(false);
  const [answers, setAnswers] = useState({});
  const [testStarted, setTestStarted] = useState(false);
  const [passed, setPassed] = useState(false);

  const questions = [
    // Level 1 questions
    [
      "I don’t have to remind the people who work for me that I am the leader.",
      "I think of each person who works for me as an individual person, not just in terms of his or her function or role.",
      "Most days I look forward to going to work.",
      "I recognize that the position I’ve been given is an opportunity to learn, not turf to be guarded.",
      "The people who work for me are willing to do work above and beyond their job descriptions.",
      "I know that dealing with people problems is a part of leading and have accepted that as part of the job.",
      "I possess the desire to learn more about leadership and become a better leader.",
      "I think of my job in terms of work to be accomplished and give very little focus to career path and the positions I desire to achieve along the way.",
      "One of my primary objectives is to assist the people who work for me.",
      "Most people find it easy to work with me.",
    ],
    // Level 2 questions
    [
      "People outside of my department or area of responsibility respect my opinions and frequently seek me out for advice.",
      "I know my strengths and weaknesses and rarely get blindsided in my work.",
      "I genuinely like most people and want to help them.",
      "I am very consistent and even-tempered in my interaction with the people who work for me.",
      "When I say something to the people on my team, they always know they can count on it because I am trustworthy.",
      "I have developed solid relationships with all of the people who work for me.",
      "The people who work with me find me likable and pleasant nearly 100 percent of the time.",
      "When I need to have a candid conversation with team members to correct errors or take care of problems, I follow through and don’t allow too much time to go by.",
      "I believe that team members desire more than just a fair day’s pay for a fair day’s work; most desire encouragement and I give it to them.",
      "I have developed relationships with everyone who works for me.",
    ],
    // level 3 questions
    [
      "I consistently hit targets and goals in my work.",
      "Good people always want to work with me and my team.",
      "People see me as an expert in my field and seek me out to learn from me.",
      "I am constantly setting and achieving higher goals for myself, even when my superiors don’t set them for me.",
      "My performance in my work often carries the team to a higher level.",
      "I give my best to whatever I do.",
      "I am comfortable with the idea that others are watching how I perform and follow my example.",
      "I am known as a problem solver, and I often get difficult tasks done.",
      "My work is very consistent on a daily basis.",
      "I have systems and routines that help me perform at a very high level.",
    ],
    // Level 4 questions
    [
      "I schedule and follow through with training and development for all the members of my team on a regular, consistent basis.",
      "When deadlines loom or work becomes urgent, we never cancel our training and development sessions.",
      "I consistently take risks by giving people responsibilities and authority that will stretch them.",
      "I spend a significant amount of time every month mentoring up-and-coming leaders.",
      "I know very thoroughly the strengths and weaknesses of all the people I lead.",
      "I individualize the way I train, develop, and mentor my people.",
      "I spend the most strategic and significant mentoring time with the people who have the highest capacity, talent, and potential.",
      "I have a history of moving people from position to position to help find their fit.",
      "I am continually giving people feedback, not just during formal reviews.",
      "My team or department is considered by others to be the best trained (or one of the best) in the organization.",
    ],
    // Level 5 questions
    [
      "I can name several specific people whom I have encouraged to speak hard truths to me, and they do so regularly.",
      "I am using my influence to instill values in my organization.",
      "The course of my organization is set by me or by a team of which I am a part of.",
      "I have developed many leaders who are developers of leaders.",
      "I enjoy the interaction and friendship of a small circle of leaders with whom I am taking the leadership journey.",
      "I am still at the top of my game, and the positive impact I am making is strong.",
      "I can name at least one person who would be ready to step in and take my place should I decide to leave my current position.",
      "I have influence outside of my organization.",
      "People from outside of my specific industry seek me out for leadership advice.",
      "I am using my influence and resources for causes greater than myself or my organization.",
    ],
  ];

  const handleStart = async () => {
    if (passed === false && testCompleted === true) {
      // Write to Firestore
      const userDocRef = doc(db, "Users", user.uid);
      await setDoc(userDocRef, { level }, { merge: true });
      const levelDocRef = doc(db, "Levels/Level" + level + "/Users", user.uid);
      await setDoc(
        levelDocRef,
        { Level: level, Email: user.email },
        { merge: true }
      );
      handleNext();
    } else {
      setTestStarted(true);
      setTestCompleted(false);
      setPassed(false);
      setAnswers(Array(10).fill(null));
    }
  };

  const handleRadioChange = (event, index) => {
    const newAnswers = [...answers];
    newAnswers[index] = event.target.value;
    setAnswers(newAnswers);
  };

  const handleSubmit = () => {
    if (answers.includes(null)) {
      alert("Please answer all questions before submitting.");
      return;
    }

    const correctAnswers = answers.filter((answer) => answer === "true").length;
    if (correctAnswers / answers.length >= 0.8) {
      if (level === 5) {
        setPassed(false);
      } else {
        setPassed(true);
        setLevel(level + 1); // Increase level if pass
      }
    }
    setTestStarted(false);
    setTestCompleted(true);
  };

  return (
    <Container>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          {!testStarted && !testCompleted ? (
            <Card
              sx={{
                m: 15,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
              }}
            >
              <CardContent>
                <Typography variant="h4">Level {level} Assessment</Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  This assessment consists of {questions[level - 1].length}{" "}
                  true/false questions. You must score at least 80% to proceed
                  to the next level. This is an assessment of your leadership
                  level and it should take 5-10 minutes to finish it.
                  <br />
                  <br />
                  Answer the questions as honestly as possible as there are no
                  right or wrong answers. The assessment is designed to help you
                  reflect on your leadership level.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleStart}
                  style={{ marginTop: "20px" }}
                >
                  Start Assessment
                </Button>
              </CardContent>
            </Card>
          ) : testCompleted ? (
            <Card
              sx={{
                m: 15,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                marginBottom: "140%",
              }}
            >
              <CardContent>
                <Typography variant="h4">Congratulations!</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 2,
                    textAlign: "center",
                  }}
                >
                  {passed
                    ? `You have completed Level ${
                        level - 1
                      }! The next level is now ${level}.`
                    : `Your current level is ${level}!`}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleStart}
                  style={{ marginTop: "20px" }}
                >
                  {passed ? "Start Next Level" : "Next Step in Signup Process"}
                </Button>
              </CardContent>
            </Card>
          ) : (
            <div>
              <Typography variant="h4" sx={{ margin: "25px" }}>
                Level {level} Test
              </Typography>
              <LinearProgress
                variant="determinate"
                value={
                  (answers.filter(Boolean).length /
                    questions[level - 1].length) *
                  100
                }
                style={{ width: "100%", marginBottom: "20px" }}
              />
              <Card
                sx={{
                  m: 5,
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  borderRadius: 2,
                }}
              >
                <CardContent>
                  {questions[level - 1].map((question, i) => (
                    <div key={i}>
                      <Typography variant="h6" align="left">
                        {question}
                      </Typography>
                      <FormControl
                        component="fieldset"
                        style={{ marginTop: "20px" }}
                      >
                        <RadioGroup
                          row
                          value={answers[i]}
                          onChange={(event) => handleRadioChange(event, i)}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="True"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="False"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: "20px" }}
                  >
                    Submit Test
                  </Button>
                </CardContent>
              </Card>
            </div>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default LevelTest;
