import React from "react";
import { Button, Grid, Typography } from "@mui/material";
// import notFoundImage from "../assets/not-found.png";

const NotFound = () => {
  return (
    <Grid
      container
      sx={{
        height: "100vh",
        // backgroundImage: `url(${notFoundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          margin: "0 auto",
          padding: "100px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "50px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            textAlign: "center",
          }}
        >
          <Typography variant="h1" gutterBottom>
            404 Not Found
          </Typography>
          <Typography variant="body1">
            The page you are looking for does not exist.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="/"
            sx={{
              marginTop: "20px",
            }}
          >
            Go Home
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default NotFound;
