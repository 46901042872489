import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PublicIcon from "@mui/icons-material/Public";
import GroupIcon from "@mui/icons-material/Group";
import AwardIcon from "@mui/icons-material/EmojiEvents";
import GlobeIcon from "@mui/icons-material/Public";
import "../../../styles/InfoCol.css";

const InfoCol = () => {
  const checkItems = [
    {
      icon: <PublicIcon />,
      text: "Empowering youth to become effective leaders",
    },
    { icon: <AwardIcon />, text: "Foster youth's personal growth" },
    { icon: <GroupIcon />, text: "Prepare youth for the workforce" },
    {
      icon: <GlobeIcon />,
      text: "Foster an appreciation for diversity",
    },
  ];

  const items = [
    { text: "developing and cultivating leadership skills" },
    { text: "promoting honesty, trustworthiness, and ethical behavior" },
    {
      text: "encouraging accountability, reliability, and taking ownership of one's actions",
    },
  ];

  return (
    <Box
      className="info-col"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        bgcolor: "background.paper",
        color: "text.primary",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: "center",
          color: "black",
          fontWeight: "bold",
          marginBottom: "2rem",
        }}
      >
        MYLO
      </Typography>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "80%",
          margin: "0 auto",
        }}
      >
        <Box sx={{ p: 4, textAlign: "center", width: "100%" }}>
          <Grid container spacing={2}>
            {checkItems.map((item, index) => (
              <Grid item xs={6} key={index}>
                <ListItem
                  sx={{
                    opacity: 0,
                    animation: "fade-in-top-bottom 2s forwards",
                    animationDelay: `${index * 0.5}s`,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: "primary.main",
                      marginRight: "1rem",
                      minWidth: "32px",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ p: 4, bgcolor: "background.paper", textAlign: "center" }}>
          <List>
            {items.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  opacity: 0,
                  animation: "fade-in-left-right 2s forwards",
                  animationDelay: `${index * 0.5}s`,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ListItemIcon sx={{ minWidth: "32px" }}>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Box>
  );
};

export default InfoCol;
