import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Registeration from "./pages/Registeration";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <div className="App">
      <div className="main-content">
        <BrowserRouter>
          <Routes>
            {/* <Route path="/Dashboard" element={<Home />} /> */}
            {/* <Route path="/Login" element={<Login />} /> */}
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Registeration />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
